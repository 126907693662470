.filter {
  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);
  @include wrapBody;
}

.filter-inner {
  position: relative;
  padding: 0 20px;
  color: map-get($colors, mainTextLight);

  @include wrapContent;
}

.filter-list {
  position: absolute;
  top: -10px;
  left: 190px;
  width: 400px;
  height: 200px;
  background: #fff;
}

.filter-text {
  position: relative;
  display: inline-block;
}

.popup {
  position: absolute;
  top: -20px;
  left: calc(100% + 20px);
  background: transparent;
  padding: 10px 60px 0;
  z-index: 99;

  @include fluid-type(width, 768px, 1366px, 360px, 960px);

  @include mq(md, max) {
    position: relative;
    top: auto;
    left: auto;
    display: block;
    vertical-align: top;
    margin: 0;
    padding-right: 30px;
    padding-left: 30px;
    width: auto;
  }
}

.popup-title {
  display: block;
  padding: 10px 0;
}

.popup-list {
  margin: 15px 0 0;
  padding: 10px 0 40px;
  list-style: none;
  columns: 300px 2;
  column-gap: 15px;
  display: none;
}

.popup-item {
  padding: 3px 0;
  display: block;
}

.popup-link {
  display: block;
}

#filter-trigger {
  cursor: pointer;
}

.has-arrow {
  position: relative;

  &:before {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #84c5c3;
    content: '';

    @include mq(md, max) {
      left: -20px;
    }
  }
}

.popup-divider {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 6px;
  display: none;
  background: linear-gradient(to right, #84c5c3, #386c7d 100%);
}

.is-triggered {
  background: #fff;
  box-shadow: 10px 15px 60px 0 rgba(#000, 0.2);

  .popup-title {
    border-bottom: 2px solid #84c5c3;
  }

  .popup-list {
    display: block;
  }

  .popup-divider {
    display: block;
  }

  .has-arrow {
    color: map-get($colors, mainTextDark);

    &:before {
      transform: rotate(90deg) translate(-9px, 0);

      @include mq(md, max) {
        display: none;
      }
    }
  }
}
