//video
.video-wrapper {
  margin: 20px 0 35px;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//image
.figure {
  .figure-link {
    @include fluid-type(margin-bottom, 384px, 1366px, 15px, 30px);

    display: block;
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    .figure-caption {
      @include fluid-type(font-size, 384px, 1366px, 12px, 16px);
    }
  }
}

.contentblock-quote {
  text-align: center;
  margin-bottom: 25px;

  .quote-title {
    margin-bottom: 8px;
  }

  .quote-author {
    font-style: italic;
    max-width: 74%;
    margin: 0 auto;
    display: block;
    padding: 0;
  }

  &.quote-small {
    .quote-title {
      @include fluid-type(font-size, 384px, 1366px, 24px, 30px);
    }

    .quote-author {
      @include fluid-type(font-size, 384px, 1366px, 16px, 18px);
    }
  }
}
