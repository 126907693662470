.bullets {
  @include fluid-type(margin-bottom, 384px, 1366px, 80px, 160px);
}

.bullets-inner {
  padding: 0 20px;

  @include wrapContent;
}

.bullets-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bullets-item {
  position: relative;
  display: block;

  @include fluid-type(padding-left, 384px, 1366px, 36px, 60px);

  &:not(:last-child) {
    @include fluid-type(margin-bottom, 384px, 1366px, 10px, 10px);
    @include fluid-type(padding-bottom, 384px, 1366px, 20px, 40px);

    &:after {
      content: '';
    }
  }

  &:before {
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 20px;
    background: #eacfa5;
    border-radius: 50%;
    content: '';

    @include mq(md, max) {
      left: 3px;
      width: 14px;
      height: 14px;
    }
  }

  &:after {
    position: absolute;
    top: 35px;
    bottom: 0;
    left: 9px;
    width: 2px;
    background: #d9e5ec;
  }

  h4 {
    font-weight: 500;
    line-height: 1.5;

    @include fluid-type(font-size, 384px, 1366px, 18px, 26px);
  }

  p {
    margin: 0;
  }
}
