.wrap-body {
  @include wrapBody;
}

.wrap-content {
  @include wrapContent;
}

.clearfix {
  @include clearfix;
}

/* stylelint-disable */
.m0 {
  margin: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.tablet-hidden {
  @include mq(lg, max) {
    display: none !important;
  }
}

.mobile-hidden {
  @include mq(md, max) {
    display: none !important;
  }
}

.mobile-visible {
  display: none !important;

  @include mq(md, max) {
    display: block !important;
  }
}

.tablet-align-left {
  @include mq(lg, max) {
    align-items: baseline !important;
  }
}


.tablet-align-right {
  @include mq(lg, max) {
    align-items: flex-end !important;
  }
}

/* stylelint-enable */
