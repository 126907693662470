html {
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
  font-family: map-get($fonts, primary);
  line-height: 1.6;
  color: map-get($colors, mainText);
  background: map-get($colors, mainBckgr);

  @include fluid-type(font-size, 384px, 1366px, 16px, 22px);
}

a {
  color: map-get($colors, mainLink);

  &:hover {
    color: map-get($colors, mainHover);
  }
}

ul,
ol {
  padding-left: 20px;
}

b,
strong {
  font-weight: 500;
}
