.newsletter {
  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);
  @include wrapBody;
}

.newsletter-inner {
  position: relative;
  padding: 0 20px;

  @include wrapContent;
}

.newsletter-container {
  @include fluid-type(padding-top padding-bottom, 384px, 1366px, 20px, 60px);
  @include fluid-type(padding-right padding-left, 384px, 1366px, 30px, 80px);
}

.newsletter-title {
  font-weight: 400;
  color: map-get($colors, mainTextLight);
  display: block;

  @include fluid-type(font-size, 384px, 1366px, 20px, 30px);
  @include fluid-type(margin-bottom, 384px, 1366px, 35px, 70px);
}

.newsletter-box {
  @include fluid-type(font-size, 384px, 1366px, 17px, 23px);
  @include clearfix;
}

.newsletter-input {
  line-height: 1;
  width: 50%;
  padding: 10px 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid map-get($colors, mainTextDark);
  transition: width map-get($timing, duration) * 2.5;
  display: inline-block;
  float: left;

  @include mq(md, max) {
    width: 100%;
    transition: 0s;
  }

  &:focus {
    outline: none;
  }
}

.newsletter-check {
  line-height: 1.2;
  position: relative;
  margin-top: 25px;
  width: 100%;
  padding-left: 35px;
  user-select: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: map-get($timing, duration) * 2.5;
  display: none;
  float: left;

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;

    &:checked {
      & ~ span {
        &:after {
          display: block;
        }
      }
    }
  }

  span {
    position: absolute;
    left: 0;
    height: 25px;
    width: 25px;

    @include fluid-type(top, 384px, 1366px, -3px, 2px);

    &:after {
      position: absolute;
      top: 7px;
      left: 10px;
      width: 5px;
      height: 10px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      display: none;
      content: '';
    }
  }
}

.newsletter-submit {
  font-weight: 500;
  text-align: center;
  margin-top: 5px;
  padding: 10px 50px;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  float: right;

  @include mq(md, max) {
    margin-top: 20px;
  }

  &:focus {
    outline: none;
  }
}
