// Wrap body
@mixin wrapBody {
  display: block;
  width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
}

// Wrap content
@mixin wrapContent {
  display: block;
  width: 100%;
  max-width: 1320px;
  margin-right: auto;
  margin-left: auto;
}

// Clearfix
@mixin clearfix {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}
