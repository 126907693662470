// Colors
$colors: (
  mainText: #796c72,
  mainTextAlt: #493d41,
  mainTextLight: #796c72,
  mainTextDark: #493d41,
  mainBckgr: #fff,
  mainLink: #796c72,
  mainHover: #493d41,
  selectionText: #fff,
  selectionBckgr: #386c7d,
);

// Breakpoints
$breakpoints: (
  xs: 384px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Animation
$timing: (
  duration: 200ms,
  delay: 200ms
);

// Fonts
$fonts: (
  primary: #{"Kohinoor W05", Arial, sans-serif},
  secondary: #{"Times W01", "Times New Roman", Times, serif}
);

// Animation for Event registration form
$transition-time: 150ms;
$transition-fx: ease-in-out;
