.intro {
  position: relative;
  width: 100%;
  margin: 0 auto;

  .title {
    margin-bottom: 6px;
  }

  &.small {
    .intro-content {
      @include fluid-type(min-height, 384px, 1366px, 350px, 600px);
    }
  }
}

.intro-inner {
  position: relative;
  overflow: hidden;

  @include wrapBody;
}

.intro-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ddd;
  background-position: center center;
  background-size: cover;

  &.has-overlay {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(135deg, rgba(#fff, 1) 0%, rgba(#fff, 0.5) 30%, rgba(#fff, 0) 100%);
      content: '';
    }
  }

  .has-svg-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('../img/svg-overlay.svg') top left / cover;
    content: '';
  }
}

.intro-content {
  position: relative;
  padding: 205px 50px;

  @include fluid-type(min-height, 384px, 1366px, 450px, 750px);
  @include wrapContent;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  .title {
    line-height: 1.3;
    color: map-get($colors, mainTextAlt);
  }

  .intro-subtitle {
    margin-top: 0;
  }
}

.intro-subtitle {
  font-weight: 400;
  color: #6a6a6a;

  @include fluid-type(font-size, 384px, 1366px, 22px, 30px);
  @include fluid-type(margin-bottom, 384px, 1366px, 20px, 60px);
}
