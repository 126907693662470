.meta {
  margin: 0;
  padding: 0;
  list-style: none;

  @include fluid-type(margin-top, 384px, 1366px, 1px, -30px);
  @include fluid-type(margin-bottom, 384px, 1366px, 10px, 40px);
  @include clearfix;
}

.meta-item {
  position: relative;
  display: inline-block;
  float: left;

  @include fluid-type(font-size, 384px, 1366px, 16px, 18px);

  &:not(:last-child) {
    padding-right: 24px;

    &:after {
      position: absolute;
      top: 0;
      right: 10px;
      content: '·';
    }
  }
}

.meta-link {
  display: block;
}
