.block {
  @include fluid-type(margin-bottom, 384px, 1366px, 60px, 120px);
  @include wrapBody;

  .block {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3 {
    font-weight: 400;
  }

  .column {
    &:nth-child(1) {
      flex: 1 1 100%;
    }

    &:nth-child(2) {
      display: flex;
      flex: 0 0 auto;

      @include fluid-type(padding-left, 384px, 1366px, 20px, 60px);

      @include mq(lg, max) {
        padding-left: 0 !important; /* stylelint-disable-line declaration-no-important */
        justify-content: center;

        @include fluid-type(padding-top, 384px, 1366px, 30px, 80px);
      }

      &.column-third {
        flex: 0 0 33.33334%;
      }
    }
  }
}

.block-inner {
  position: relative;
  padding: 0 20px;

  @include wrapContent;
}

.block-columns {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include mq(lg, max) {
    display: block;
    //flex-flow: column nowrap;
  }
}

.block-subtitle {
  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);
}

.block-column {
  position: relative;
  width: 100%;

  @include fluid-type(padding, 384px, 1366px, 30px, 80px);

  &:nth-child(1) {
    flex: 1 1 100%;
    max-width: 100%;

    @include mq(lg, max) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &:nth-child(2) {
    flex: 0 0 calc(33.33334% + 13px);
    max-width: calc(33.33334% + 13px);

    @include fluid-type(padding, 768px, 1366px, 30px, 80px);

    @include mq(lg, max) {
      flex-basis: 100%;
      max-width: 100%;
      //padding-right: 0;
      //padding-left: 0;
    }
  }

  &-main {
    padding-right: 0;

    @include mq(lg, max) {
      padding: 40px 30px;
    }

    @include mq(md, max) {
      padding: 30px 0;
    }
  }

  &-side {
    padding-right: 0;

    @include mq(lg, max) {
      padding: 30px 0;
    }

    @include mq(md, max) {
      padding: 40px 30px;
    }
  }
}

.block-title {
  color: #6a4e35;

  @supports (-webkit-background-clip: text) {
    background: linear-gradient(to right, #eacfa5, #6a4e35 100%);
    -webkit-background-clip: text !important; /* stylelint-disable-line declaration-no-important */
    -webkit-text-fill-color: transparent !important; /* stylelint-disable-line declaration-no-important */
  }
}

.block-divider {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 6px;
  background: linear-gradient(to right, #84c5c3, #386c7d 100%);
}

.date {
  font-size: 18px;
  color: map-get($colors, mainTextLight);
  margin-bottom: 15px;
  display: block;
}

.block-attribute {
  @include fluid-type(margin-top, 384px, 1366px, 20px, 60px);

  strong {
    display: block;
  }

  span {
    display: block;
  }
}

.block-contact {
  @include fluid-type(margin-top, 384px, 1366px, 60px, 260px);
}
