*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: #444;
  background: #fff;
}

hr {
  overflow: visible;
  box-sizing: content-box;
  height: 0;
}

a {
  text-decoration: none;
  color: #4c74b7;
  background-color: transparent;

  &:hover {
    text-decoration: underline;
    color: #365383;
  }
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sup,
sub {
  font-size: 65%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 1rem;
}

pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
  overflow: auto;
  margin-top: 0;
  margin-bottom: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

textarea {
  resize: vertical;
}

figure {
  margin: 0 0 1rem;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}
