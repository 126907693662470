.vbcn-iframe[data-src] {
  display: none;
}

#cn-cookie-settings {
  font-size: 14px;
  font-weight: 500;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}
