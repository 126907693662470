.header {
  //position: relative;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;

  .link {
    color: #fff;
  }
}

.header-inner {
  position: relative;
  padding: 30px;

  @include fluid-type(padding-top padding-bottom, 384px, 1366px, 60px, 70px);
  @include wrapContent;
  @include clearfix;
}

.logo {
  display: inline-block;
  float: left;
}

.logo-icon {
  @include fluid-type(width, 384px, 1366px, 212px, 246px);
  @include fluid-type(height, 384px, 1366px, 56px, 65px);
}

.back {
  color: rgba(#fff, 0.9);
  display: inline-block;
  float: left;

  &:hover,
  &:focus {
    text-decoration: none;
    color: rgba(#fff, 1);
  }
}

.back-icon {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 20px;
  display: block;
}

.back-text {
  font-size: 19px;
  line-height: 1.9;
}

.menu {
  position: relative;
  float: right;
  z-index: 10;

  @include mq(md, min) {
    display: block !important; /* stylelint-disable-line declaration-no-important */
  }

  @include mq(md, max) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background: #fff;
    transform: translateX(100%);
    opacity: 0;
    transition: map-get($timing, duration);
    overflow-y: auto;
  }

  @media (max-width: 350px) {
    width: 75%;
  }

  &.is-visible {
    opacity: 1;
    transform: translateX(0);
  }

  &.is-bigger {
    width: calc(100% - 20px);
  }
}

.menu-overlay,
.contact-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#000, 0.5);
  display: none;
  z-index: 9;
}

.menu-overlay {
  @include mq(md, min) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq(md, max) {
    padding: 40px;
  }
}

.menu-item {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  float: left;

  @include mq(md, max) {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    display: block;
    margin: 10px 0 40px 10px;
    float: none;
  }

  &:not(:last-child) {
    @include mq(md, min) {
      padding-right: 24px;

      &:after {
        position: absolute;
        top: 0;
        right: 10px;
        content: '·';
      }
    }
  }
}

.menu-link {
  color: map-get($colors, mainTextAlt);
  display: block;

  &:hover,
  &:focus {
    text-decoration: none;
    color: #000;
  }
}

.menu-kontakt {
  position: absolute;
  top: 0;
  right: 20px;
  width: 100%;
  background: #fff;
  transform: translateY(-100%);
  opacity: 0;
  transition: map-get($timing, duration);
  z-index: 11;

  @include fluid-type(font-size, 384px, 1366px, 18px, 22px);
  @include fluid-type(line-height, 384px, 1366px, 30px, 36px);
  @include fluid-type(max-width, 768px, 1366px, 300px, 880px);

  @include mq(lg, max) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 40px);
    transform: translate(100%, 0%);
    background: #fff;
    overflow-y: auto;
    max-width: initial;
  }

  &-inner {
    height: 100%;
    overflow-y: auto;

    @include fluid-type(padding, 384px, 1366px, 40px, 80px);

    .link,
    .block-divider {
      @include mq(md, max) {
        display: none;
      }
    }
  }

  &-header {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border-bottom: 2px solid #bda27b;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    @include mq(md, max) {
      margin-bottom: 0;
      border: none;
    }

    h4 {
      font-weight: 500;
      margin: 0;

      @include mq(md, max) {
        display: none;
      }
    }
  }

  &-columns {
    columns: 200px 2;

    @include fluid-type(column-gap, 384px, 1366px, 20px, 40px);
  }

  .trigger-menu-close {
    margin: 0;
    display: flex;

    .trigger-text {
      @include mq(md, max) {
        display: none;
      }
    }
  }

  &.is-visible {
    opacity: 1;
    transform: translateY(0);
  }
}

.trigger {
  cursor: pointer;
}

.trigger-icon {
  width: 21px;
  height: 21px;
  display: block;
}

.trigger-text {
  margin-left: 10px;
}

.trigger-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &-open {
    float: right;
    display: none;

    @include fluid-type(margin-top, 384px, 1366px, 12px, 17px);

    @include mq(md, max) {
      display: flex;
    }
  }

  &-close {
    display: none;
    margin: 76px 0 0 40px;

    @include mq(md, max) {
      display: flex;
    }
  }
}

.trigger-kontakt-open,
.trigger-kontakt-close {
  .trigger-icon {
    color: #386c7d;
  }
}

.trigger-kontakt-close {
  .trigger-text {
    @include mq(md, max) {
      display: none;
    }
  }
}
