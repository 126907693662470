.waves {
  overflow: hidden;

  @include fluid-type(margin-top, 384px, 1366px, 40px, 80px);
  @include fluid-type(margin-bottom, 384px, 1366px, 80px, 160px);

  .waves-graphic {
    width: 100vw;

    @include mq(md, max) {
      width: 200vw;
    }
  }
}

.waves-content {
  background: linear-gradient(to bottom, #fcf9f5 0%, #fff 50%, #fcf9f5 100%);

  @include fluid-type(padding-top padding-bottom, 384px, 1366px, 80px, 125px);

  &.white {
    background: #fff;
    padding: 0;
  }

  &.oneway {
    background: linear-gradient(to bottom, #fcf9f5 0%, #fff 50%, #fff 100%);
  }

  &.regular-color {
    background: #fcf9f5;
    padding-bottom: 0;
  }
}

.waves-backg {
  fill: #fcf9f5;
}

.waves-lines {
  fill: #eae3da;
}

.more-button {
  font-size: 18px;
  font-weight: 400;
  margin-left: -10px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: map-get($colors, mainText);
}

.more-button-text {
  color: map-get($colors, mainText);
}

.more-content {
  padding-top: 30px;
}
