.slider {
  padding: 0 10px;

  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);
  @include wrapContent;

  max-width: 1290px;

  .flickity-viewport {
    transition: height map-get($timing, duration);
  }
}

.sliderel {
  width: 100%;
}

.carousel-cell {
  width: 50%;
  text-align: center;

  @include fluid-type(padding-right padding-left, 1200px, 1600px, 10px, 20px);

  @include mq(md, max) {
    width: 100%;
  }

  &-inner {
    width: 100%;
  }
}

.flickity-page-dots {
  position: relative;

  .dot {
    margin: 0 5px;
    background: #d9e4ec;
    border-radius: 0;
    opacity: 1;
    cursor: pointer;

    @include fluid-type(width, 384px, 1366px, 60px, 120px);
    @include fluid-type(height, 384px, 1366px, 4px, 6px);

    &.is-selected {
      background: #796c72;
    }
  }
}
