.footer {
  position: relative;

  @include fluid-type(margin-top, 384px, 1366px, 90px, 140px);
}

.footer-amblem {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.05;
  z-index: -1;

  @include fluid-type(width height, 384px, 1366px, 250px, 1000px);

  @include mq(md, max) {
    display: none;
  }
}

.footer-limiter {
  @include wrapBody;
}

.footer-divider {
  width: 100%;
  height: 6px;
  background: linear-gradient(to right, #84c5c3, #386c7d 100%);
}

.footer-inner {
  text-align: center;
  padding: 20px;

  @include fluid-type(padding-top, 384px, 1366px, 30px, 70px);
  @include fluid-type(padding-bottom, 384px, 1366px, 45px, 70px);
  @include wrapContent;
  @include clearfix;
}

.footer-logo {
  display: inline-block;
  float: left;

  @include mq(md, max) {
    display: none;
  }

  &-mobile {
    display: none;

    @include mq(md, max) {
      display: block;
    }
  }
}

.footer-logo-icon {
  @include fluid-type(width, 384px, 1366px, 160px, 246px);
  @include fluid-type(height, 384px, 1366px, 42px, 65px);
}

.footer-list {
  float: right;
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;

  @include clearfix;

  @include mq(md, max) {
    float: none;
    margin: 0 0 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.footer-item {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  float: left;

  @include mq(md, max) {
    display: inline;
    float: none;
  }

  &:not(:last-child) {
    padding-right: 24px;

    &:after {
      position: absolute;
      top: 0;
      right: 10px;
      content: '·';
    }
  }
}

.footer-link {
  color: map-get($colors, mainTextAlt);
  display: block;

  @include mq(md, max) {
    display: inline-block;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: #000;
  }
}
