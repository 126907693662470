.quote {
  @include fluid-type(margin-top, 384px, 1366px, 50px, 330px);
  @include fluid-type(margin-bottom, 384px, 1366px, 70px, 400px);
  @include wrapBody;
}

.quoteimg {
  position: relative;

  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);
  @include wrapBody;
  @include clearfix;

  .quote-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(66.66667% - 40px);
    background-position: center center;
    background-size: cover;

    @include mq(md, max) {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      width: 100%;
      height: 300px;
    }
  }

  .quote-content {
    position: relative;
    float: right;
    width: calc(66.66667% - 14px);
    margin: 80px 0;

    @include fluid-type(padding-top, 384px, 1366px, 50px, 160px);
    @include fluid-type(padding-bottom, 384px, 1366px, 50px, 110px);
    @include fluid-type(padding-right padding-left, 384px, 1366px, 30px, 80px);
    //@include fluid-type(padding, 384px, 1366px, 30px, 80px);

    @include mq(lg, max) {
      width: calc(66.66667% - 11px);
    }

    @include mq(md, max) {
      width: 100%;
      margin: -50px 0 0;
    }
  }
}

.quote-inner {
  position: relative;
  padding: 0 20px;

  @include wrapContent;
  @include clearfix;
}

.quote-content {
  text-align: center;
}

.quote-icon {
  opacity: 0.1;
  margin: 0 auto 15px;
  display: block;

  @include fluid-type(width height, 384px, 1366px, 100px, 260px);
}

.quote-image {
  width: 520px;
}

.quote-title {
  font-family: map-get($fonts, secondary);
  font-style: italic;
  line-height: 1.2;
  letter-spacing: -0.1px;

  @include fluid-type(font-size, 384px, 1366px, 30px, 64px);
  @include fluid-type(margin-top, 384px, 1366px, 30px, 60px);

  color: #386c7d;

  @supports (-webkit-background-clip: text) {
    background: linear-gradient(to right, #84c5c3, #386c7d 100%);
    -webkit-background-clip: text !important; /* stylelint-disable-line declaration-no-important */
    -webkit-text-fill-color: transparent !important; /* stylelint-disable-line declaration-no-important */
  }
}

.quote-author {
  @include fluid-type(padding-top, 384px, 1366px, 20px, 40px);
}
