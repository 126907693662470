.tabs {
  position: relative;

  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);
}

.tabs-waves {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;

  svg {
    width: 150vw;
    display: block;
    margin: 0 auto;
    transition: map-get($timing, duration) * 3;

    @include mq(md, max) {
      width: 350vw;
    }
  }
}

.tabs-limiter {
  @include wrapBody;
}

.tabs-inner {
  position: relative;
  padding: 0 20px;

  @include wrapContent;
}

.tabs-list {
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @include mq(md, max) {
    margin: 0;
  }
}

.tabs-front {
  position: relative;
  background: #fff;
  box-shadow: 10px 15px 60px 0 rgba(#000, 0.2);

  @include mq(md, max) {
    background: transparent;
    box-shadow: none;
  }

  .tabs-content {
    position: relative;

    @include fluid-type(padding-right padding-left, 384px, 1366px, 30px, 80px);
    @include fluid-type(padding-bottom, 384px, 1366px, 30px, 80px);

    @include mq(md, max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &.blank {
    background: transparent;
    box-shadow: none;

    .tabs-header {
      padding-top: 20px !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.tabs-header {
  position: relative;
  padding-top: 74px !important; /* stylelint-disable-line declaration-no-important */

  @include fluid-type(padding-right padding-left, 384px, 1366px, 30px, 80px);

  @include mq(md, max) {
    margin-bottom: 30px;
    padding-top: 35px !important; /* stylelint-disable-line declaration-no-important */
    padding-right: 0;
    padding-left: 0;
  }

  .title {
    margin-bottom: 30px;
    display: none;

    @include mq(md, max) {
      display: block;
    }
  }

  &.colorfull {
    background: #fff;

    @include fluid-type(padding-bottom, 384px, 1366px, 30px, 60px);

    @include mq(md, max) {
      padding-right: 30px;
      padding-left: 30px;
      box-shadow: 10px 15px 60px 0 rgba(#000, 0.2);
    }
  }
}

.tabs-inside {
  .tabs-list {
    @include fluid-type(padding-bottom, 384px, 1366px, 10px, 40px);
  }

  .tab-content-main {
    display: flex;
    flex-flow: row nowrap;

    @include mq(md, max) {
      flex-flow: column nowrap;
    }
  }
}

.tab-content {
  display: none;

  &:nth-child(1) {
    display: block;
  }
}

.tabs-block {
  background: #fff;

  @include fluid-type(padding, 384px, 1366px, 30px, 80px);
}

.tabs-item {
  position: relative;
  color: map-get($colors, mainTextLight);
  display: flex;
  margin: 0 10px 20px;
  flex: 0 0 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: map-get($timing, duration);

  @include fluid-type(font-size, 384px, 1366px, 16px, 18px);
  @include fluid-type(padding-top padding-bottom, 384px, 1366px, 10px, 20px);
  @include fluid-type(padding-right padding-left, 384px, 1366px, 15px, 30px);

  @include mq(md, max) {
    flex-basis: 100%;
    justify-content: flex-start;
    text-align: left;
    margin-right: 0;
    margin-left: 0;
  }

  &-link {
    display: none;

    @include mq(md, max) {
      display: inline-block;

      &:last-child {
        .tabs-item {
          margin-bottom: 0;
        }
      }
    }
  }

  .threetabs {
    .tabs-item-link {
      display: inline-block;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 6px;
    background: #d9e5ec;
    opacity: 0.5;
    transition: map-get($timing, duration);
    content: '';

    @include mq(md, max) {
      opacity: 1;
    }

    @include mq(lg, max) {
      height: 5px;
    }

    @include mq(md, max) {
      right: auto;
      top: 0;
      width: 4px;
      height: auto;
      background: linear-gradient(to bottom, #84c5c3, #386c7d 100%);
    }
  }

  &:hover,
  &:focus,
  &.is-active {
    color: map-get($colors, mainTextAlt);
    text-shadow: 2px 2px 4px rgba(#000, 0.2);
    transform: translateY(-10px);

    @include mq(md, max) {
      transform: translate(10px, 0);
    }

    &:before {
      opacity: 1;
      transform: translateY(10px);

      @include mq(md, max) {
        transform: translate(-10px, 0);
      }
    }
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.colorfull {
  .tabs-item {
    &:before {
      background: linear-gradient(to right, #f1ccbc, #be8b7b 100%);

      @include mq(md, max) {
        background: linear-gradient(to bottom, #f1ccbc, #be8b7b 100%);
      }
    }
  }
}

.twotabs {
  .tabs-item {
    @include fluid-type(font-size, 384px, 1366px, 18px, 22px);

    &:before {
      opacity: 1;
    }

    &:hover,
    &:focus,
    &.is-active {
      &:before {
        opacity: 0.5;
      }
    }
  }

  @include mq(md, max) {
    flex-flow: column nowrap;
  }

  li {
    max-width: calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);

    @include mq(md, max) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.threetabs {
  @include fluid-type(margin-bottom, 384px, 1366px, 30px, 80px);

  @include mq(md, max) {
    flex-flow: column nowrap;
  }

  .tabs-item {
    &:before {
      opacity: 1;
    }

    &:hover,
    &:focus {
      &:before {
        opacity: 0.5;
      }
    }
  }

  a {
    max-width: calc(33.33334% - 20px);
    flex: 0 0 calc(50% - 20px);

    @include mq(md, max) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}
