$colors:
  'verm' #f1ccbc #be8b7b linear-gradient(to right, #f1ccbc, #be8b7b 100%) linear-gradient(to bottom, #f1ccbc, #be8b7b 100%),
  'immo' #a3d8e7 #598fbe linear-gradient(to right, #a3d8e7, #598fbe 100%) linear-gradient(to bottom, #a3d8e7, #598fbe 100%),
  'bav' #f5e5c0 #e0b15c linear-gradient(to right, #f5e5c0, #e0b15c 100%) linear-gradient(to bottom, #f5e5c0, #e0b15c 100%),
  'stiff' #dbaabb #b164a5 linear-gradient(to right, #dbaabb, #b164a5 100%) linear-gradient(to bottom, #dbaabb, #b164a5 100%),
  'fo' #86c3b1 #3b6b6a linear-gradient(to right, #86c3b1, #3b6b6a 100%) linear-gradient(to bottom, #86c3b1, #3b6b6a 100%),
  'global' #bda27c #6a4e35 linear-gradient(to right, #eacfa5, #6a4e35 100%) linear-gradient(to bottom, #eacfa5, #6a4e35 100%);

/* stylelint-disable */
$rgbColors:
  'verm'
  '241, 204, 188'
  '190, 139, 123'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmMWNjYmMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz48L3N2Zz4='
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmMWNjYmMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmMWNjYmMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTUgMTEgTDEwIDE2IDE1IDIxIE0yMiAxNiBMMTAgMTYiIC8+PC9zdmc+',

  'immo'
  '163, 216, 231'
  '89, 143, 190'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNhM2Q4ZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz48L3N2Zz4='
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNhM2Q4ZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNhM2Q4ZTciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTUgMTEgTDEwIDE2IDE1IDIxIE0yMiAxNiBMMTAgMTYiIC8+PC9zdmc+',

  'bav'
  '245, 229, 192'
  '224, 177, 92'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNWU1YzAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz48L3N2Zz4='
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNWU1YzAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmNWU1YzAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTUgMTEgTDEwIDE2IDE1IDIxIE0yMiAxNiBMMTAgMTYiIC8+PC9zdmc+',

  'stiff'
  '219, 170, 187'
  '177, 100, 165'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkYmFhYmIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz48L3N2Zz4='
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkYmFhYmIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkYmFhYmIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTUgMTEgTDEwIDE2IDE1IDIxIE0yMiAxNiBMMTAgMTYiIC8+PC9zdmc+',

  'fo'
  '134, 195, 177'
  '59, 107, 106'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiM4NmMzYjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz48L3N2Zz4='
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiM4NmMzYjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiM4NmMzYjEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTUgMTEgTDEwIDE2IDE1IDIxIE0yMiAxNiBMMTAgMTYiIC8+PC9zdmc+',

  'global'
  '234, 207, 165'
  '106, 78, 53'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNiZGEyN2MiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxNCIgLz48L3N2Zz4='
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNiZGEyN2MiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+'
  'data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNiZGEyN2MiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTUgMTEgTDEwIDE2IDE1IDIxIE0yMiAxNiBMMTAgMTYiIC8+PC9zdmc+';
/* stylelint-enable */

@each $rgbColor in $rgbColors {
  body.#{nth($rgbColor, 1)} {
    .text-link {
      &:before {
        background-image: #{'url('nth($rgbColor, 4) ')'};
      }

      &:after {
        background-image: #{'url('nth($rgbColor, 5) ')'};
      }

      &.prev {
        &:after {
          background-image: #{'url('nth($rgbColor, 6) ')'};
        }
      }
    }

    // Bullets
    .bullets-item {
      &:nth-child(1n):before {
        background: #{'rgba('nth($rgbColor, 2) ', 0.7)'};
      }

      &:nth-child(2n):before {
        background: #{'rgba('nth($rgbColor, 2) ', 1)'};
      }

      &:nth-child(3n):before {
        background: #{'rgba('nth($rgbColor, 3) ', 0.7)'};
      }

      &:nth-child(4n):before {
        background: #{'rgba('nth($rgbColor, 3) ', 1)'};
      }

      &:after {
        background: #{'rgba('nth($rgbColor, 2) ', 0.7)'};
      }
    }
  }
}

@each $color in $colors {
  body.#{nth($color, 1)} {

    // Shared
    .link {
      svg {
        color: #{nth($color, 2)};
      }
    }

    .more-button {
      color: #{nth($color, 2)};
    }

    // Popup {
    .has-arrow {
      &:before {
        border-color: transparent transparent transparent #{nth($color, 3)};
      }
    }

    .popup-title {
      border-color: #{nth($color, 3)};
    }

    // header
    .menu-kontakt-header {
      border-color: #{nth($color, 2)};
    }

    .trigger-menu-open,
    .trigger-menu-close,
    .trigger-kontakt-icon,
    .trigger-kontakt-close {
      .trigger-icon {
        color: #{nth($color, 2)};
      }
    }

    // Quote
    .quote-title,
    .block-title,
    .popup-divider,
    .block-divider,
    .footer-divider {
      color: #{nth($color, 4)};

      @supports (background: #{nth($color, 4)}) {
        background: #{nth($color, 4)};
      }
    }

    // Searchform
    .newsletter-check {
      input {
        &:checked {
          & ~ span {
            background-color: #{nth($color, 3)};
          }
        }
      }

      span {
        background: #{nth($color, 2)};
      }
    }

    .newsletter-submit {
      background: #{nth($color, 4)};
    }

    // Tabs
    .tabs-list {
      .tabs-item {
        &.is-active {
          &:before {
            background: #{nth($color, 4)};

            @include mq(md, max) {
              background: #{nth($color, 5)};
            }
          }
        }
      }
    }
  }

  // Title
  .block-title {
    &.#{nth($color, 1)} {
      color: #{nth($color, 3)};

      @supports (-webkit-background-clip: text) {
        background: #{nth($color, 4)} !important; /* stylelint-disable-line declaration-no-important */
        -webkit-background-clip: text !important; /* stylelint-disable-line declaration-no-important */
        -webkit-text-fill-color: transparent !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  // Tabs
  .tabs-list {
    &.colorfull {
      .tabs-item {
        &.#{nth($color, 1)} {
          &:before {
            background: #{nth($color, 4)};

            @include mq(md, max) {
              background: #{nth($color, 5)};
            }
          }

          &.is-active {
            &:before {
              background: #{nth($color, 4)};

              @include mq(md, max) {
                background: #{nth($color, 5)};
              }
            }
          }
        }
      }
    }
  }
}
