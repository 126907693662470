.mfp {
  &-figure {
    &:after {
      top: 60px;
      bottom: 60px;

      @media (max-width: 900px) {
        top: 40px;
        bottom: 40px;
      }
    }
  }

  &-bg {
    opacity: 0.9;
  }

  &-img {
    padding: 60px 0 !important; /* stylelint-disable-line declaration-no-important */

    @media (max-width: 900px) {
      padding: 40px 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &-arrow {
    &-left,
    &-right {
      &:before,
      &:after {
        position: absolute;
        top: 30px;
        left: 25px !important; /* stylelint-disable-line declaration-no-important */
        width: 40px;
        height: 40px;
        margin: 0;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 40px 40px;
        border: none;
        transition: map-get($timing, duration);
        content: '';
      }
    }

    &-left {
      &:before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkOWU1ZWMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIj4gICAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iMTMiIC8+PC9zdmc+);
      }

      &:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkOWU1ZWMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIj4gICAgPHBhdGggZD0iTTE1IDExIDEwIDE2IDE1IDIxIE0xMiAxNiBMMjIgMTYiIC8+PC9zdmc+);
        left: 20px;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          transform: translateX(-9px);
        }
      }
    }

    &-right {
      &:before {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkOWU1ZWMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjE2IiByPSIxMyIgLz48L3N2Zz4=);
      }

      &:after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1pbmZvIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBmaWxsPSJub25lIiBzdHJva2U9IiNkOWU1ZWMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxLjUiPiAgICA8cGF0aCBkPSJNMTcgMTEgTDIyIDE2IDE3IDIxIE0yMSAxNiBMMTAgMTYiIC8+PC9zdmc+);
        left: 20px;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          transform: translateX(9px);
        }
      }
    }
  }

  &-close {
    text-align: center !important; /* stylelint-disable-line declaration-no-important */
    text-indent: -999rem;
    line-height: 40px;
    position: absolute;
    top: 15px;
    right: auto;
    left: 0;
    width: 40px !important; /* stylelint-disable-line declaration-no-important */
    height: 40px;
    margin: 0;
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
    background-position: center center !important; /* stylelint-disable-line declaration-no-important */
    background-repeat: no-repeat !important; /* stylelint-disable-line declaration-no-important */
    background-size: 20px 20px !important; /* stylelint-disable-line declaration-no-important */
    border: none;

    @media (max-width: 900px) {
      top: 0;
      transform: scale(0.75);
    }

    &:active {
      top: 14px;

      @media (max-width: 900px) {
        top: -1px;
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      display: block;
      transition: map-get($timing, duration);
      content: '';
    }

    &:before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1jbG9zZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDllNWVjIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ij4gICAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iMTMiIC8+PC9zdmc+);
    }

    &:after {
      background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iaS1jbG9zZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDllNWVjIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMS41Ij4gICAgPHBhdGggZD0iTTEwIDIyIEwyMiAxMCBNMjIgMjIgTDEwIDEwIiAvPjwvc3ZnPg==);
    }

    &:hover {
      &:before {
        opacity: 0;
      }

      &:after {
        transform: rotate(90deg);
      }
    }

    @media (max-width: 900px) {
      line-height: 28px;
      width: 30px !important; /* stylelint-disable-line declaration-no-important */
      height: 30px;
    }

    &-text {
      position: absolute;
      top: 20px;
      left: 50px;
      color: #b2b2b2;

      @media (max-width: 900px) {
        top: 6px;
        left: 40px;
      }
    }
  }

  &-pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 5px;
      background: #796c72;
      cursor: pointer;

      @include fluid-type(width, 384px, 1366px, 60px, 120px);
      @include fluid-type(height, 384px, 1366px, 4px, 6px);

      &.is-selected {
        background: #d9e5ec;
      }
    }
  }
}
