//$colors-map
.alert + .gdpr-form {
  padding-top: 50px;
}

.gdpr-form,
.event-registration-form {
  .honey {
    position: absolute;
    left: -9999px;
  }

  .chars-left-counter {
    position: absolute;
    top: 0;
    right: 0;
  }

  .submit-button {
    position: relative;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 1em;
    background: none;
    outline: none;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    height: 200px;
    width: 100%;
    display: flex;
    margin-top: 50px;
    padding-bottom: 30px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid #bada55;
    color: #bada55;

    &:hover {
      background-color: #bada55;
      color: white;
    }

    .button-inner {
      position: relative;
      margin-top: 30px;
      margin-bottom: 10px;
      color: currentColor;
      text-transform: uppercase;
      display: inline-block;
      font-size: 1em;
      background: none;
      border: none;
      outline: none;
      padding-left: 0;
      padding-right: 0;

      &:after {
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        border-bottom: 2px solid currentColor;
        content: '';
      }
    }
  }

  .inputgroup {
    //padding-top: 24px;

    //.text-label {
    //  transform: translate3d(14px, 44px, 0);
    //  transition: transform $transition-time $transition-fx, color $transition-time $transition-fx;
    //  position: relative;
    //  z-index: 1;
    //  font-size: 16px;
    //  line-height: 24px;

    //  &.is-at-final-position {
    //    transform: translate3d(0, 0, 0);
    //    font-size: 14px;
    //    line-height: 24px;
    //  }
    //}

    //&.has-filled-input {
    //  .text-label {
    //    transform: translate3d(0, 0, 0);
    //    font-size: 14px;
    //    line-height: 24px;
    //  }
    //}
  }
}

.event-schedules-container {
  border: 2px solid #333;
  padding: 64px/2 64px 0;
}

.registrant-guest {
  @include clearfix;

  margin-bottom: 2.5rem;

  .registrant-guest-headline {
    margin-bottom: 2.5rem / 2;
    font-weight: 400;
  }
}

.event-form {
  //padding-top: 80px;
}

.event-section {
  padding: 0;
  border: 0;

  @include fluid-type(margin-bottom, 384px, 1366px, 50px, 70px);

  .intro-subtitle {
    margin-bottom: 20px;
  }

  &.is-inactive {
    opacity: 0.4;

    * {
      cursor: not-allowed;
    }
  }
}

.event-user {
  @include fluid-type(margin-bottom, 384px, 1366px, 20px, 40px);
}

.event-row {
  width: calc(100% + 30px);
  margin: 0 -15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  @include clearfix;

  @include mq(md, max) {
    display: block;
  }
}

.event-column {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;

  @include mq(md, max) {
    max-width: 100%;
    flex-basis: 100%;
  }

  &:last-child {
    padding-left: 20px;
  }
}

.event-el {
  position: relative;
  width: 100%;
  margin-bottom: 60px;
  padding: 0 15px;

  &:before {
    position: absolute;
    bottom: -1px;
    right: 15px;
    left: 15px;
    height: 1px;
    background: #796c72;
    display: block;
  }

  &.has-active-input {
    &:before {
      content: '';
    }
  }

  &.has-input-error {
    margin-bottom: 90px;

    input {
      border-bottom-color: #e88300;
    }

    &.has-active-input {
      input {
        background: rgba(#e88300, 0.05);
      }

      &:before {
        background: #e88300;
        content: '';
      }
    }
  }
}

.event-add-schedule {
  text-align: left;
  border: 0;
  background: #fff;
  margin-bottom: 20px;

  &:focus {
    outline: 0;
  }

  &:hover {
    svg {
      transform: rotate(225deg);
    }
  }
}

.event-remove {
  &:hover {
    svg {
      transform: scale(1.2) rotate(90deg);
    }
  }
}

.event-invite-guests {
  margin-bottom: 60px;

  .event-user {
    //margin-bottom: 80px;
  }
}

.event-select {
  position: relative;
  margin-bottom: -25px;
  z-index: 1000;

  select {
    display: block;
    opacity: 0;
    pointer-events: none;
  }

  .select-selected {
    font-weight: 500;
    position: relative;
    color: map-get($colors, mainTextDark);
    margin-left: 40px;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 2px solid #fff;
    z-index: 100;

    @include fluid-type(font-size, 320px, 1366px, 17px, 22px);

    &:after {
      position: absolute;
      top: 22px;
      left: -40px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-color: #bda27c transparent transparent transparent;
      content: '';
    }

    &.select-arrow-active {
      border-color: #bda27c;

      &:after {
        top: 12px;
        border-color: transparent transparent #bda27c transparent;
      }
    }
  }

  .select-items {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    padding: 25px 0;
    background: #fff;
    z-index: 99;

    &:before {
      position: absolute;
      top: -67px;
      right: -40px;
      bottom: 0;
      left: -30px;
      background: #fff;
      box-shadow: 5px 7px 30px 0 rgba(#000, 0.25);
      z-index: -1;
      content: '';
    }

    &.select-hide {
      display: none;
    }

    div {
      color: map-get($colors, mainTextDark);
      padding: 4px 8px 4px 40px;
      cursor: pointer;

      @include fluid-type(font-size, 320px, 1366px, 17px, 22px);
    }
  }
}

.event-checkbox {
  position: relative;
  display: block;
  padding-left: 40px;
  user-select: none;
  cursor: pointer;

  @include fluid-type(font-size, 320px, 1366px, 17px, 22px);

  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;

    &:checked,
    &:hover:checked {
      & ~ .checkmark {
        &:after {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &:disabled {
      & ~ .checkmark {
        &:after {
          display: none;
        }
      }
    }

    &:hover {
      & ~ .checkmark {
        transform: scale(1.2);

        &:after {
          visibility: visible;
          opacity: 0.5;
          transform: scale(0.7);
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    background: transparent;
    border: 2px solid #bda27c;
    border-radius: 4px;
    transition: 0.2s;

    &:before {
      font-weight: 700;
      text-align: center;
      line-height: 23px;
      color: #bda27c;
      position: absolute;
      top: 0;
      left: 0;
      width: 21px;
      height: 21px;
      transition: 0.2s;
    }

    &:after {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 15px;
      height: 15px;
      background: #bda27c;
      visibility: hidden;
      opacity: 0;
      transition: 0.2s;
      content: '';
    }
  }

  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.event-label {
  position: absolute;
  top: 0;
  left: 15px;
  pointer-events: none;
  transition: map-get($timing, duration);

  &.is-moved {
    font-size: 14px;
    transform: translateY(-17px);
  }
}

.event-label-empty,
.event-label-invalid {
  position: absolute;
  top: 100%;
  right: 15px;
  left: 15px;
  color: #e88300;
  font-size: 18px;
  line-height: 1.2;
  margin-top: 10px;
}

.has-filled-input {
  .event-label {
    font-size: 14px;
    transform: translateY(-17px);
  }
}

.event-input {
  font-weight: 500;
  width: 100%;
  padding: 7px 0;
  display: block;
  background: #fff;
  border: 0;
  border-bottom: 1px solid map-get($colors, mainText);

  @include fluid-type(font-size, 320px, 1366px, 16px, 18px);

  &:not(:placeholder-shown) {
    border-bottom-width: 1px;
  }

  &::-webkit-input-placeholder {
    font-weight: 400;
    color: rgba(#796c72, 0.5);
  }

  &::-moz-placeholder {
    font-weight: 400;
    color: rgba(#796c72, 0.5);
  }

  &:-ms-input-placeholder {
    font-weight: 400;
    color: rgba(#796c72, 0.5);
  }

  &:-moz-placeholder {
    font-weight: 400;
    color: rgba(#796c72, 0.5);
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

.event-remove,
.event-add {
  color: #bda27c;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;

  svg {
    min-width: 40px;
    margin-right: 15px;
    transform: rotate(45deg);
    transition: transform 0.2s;
  }

  &-blue {
    color: #bfcfd9;
  }
}

.event-add-text {
  line-height: 1.3;
  color: map-get($colors, mainTextDark);

  @include fluid-type(font-size, 320px, 1366px, 20px, 24px);

  span {
    display: block;

    @include fluid-type(font-size, 320px, 1366px, 15px, 18px);
  }
}

.event-remove {
  float: right;
  font-size: 18px;
  font-weight: 300;
  transform: translateY(18px);

  @include mq(md, max) {
    transform: translateY(0);
    float: none;
  }

  svg {
    margin-right: 5px;
    transform: rotate(0);
  }
}

.event-user {
  &.is-hidden {
    display: none;
  }

  &.is-visible {
    display: block;
  }
}

.event-limit {
  display: none;
}

.event-button {
  float: right;
  font-weight: 400;
  color: #fff;
  padding: 15px 75px;
  display: inline-block;
  background-color: #bda27c;
  border: 0;
  border-radius: 6px;
  background-image: linear-gradient(to right, #eacfa5, #6a4e35);
  cursor: pointer;

  @include fluid-type(font-size, 320px, 1366px, 17px, 22px);

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-image: linear-gradient(to right, #a68b6a, #6a4e35);
    }

    &:active {
      background-color: #493d41;
      background-image: linear-gradient(to right, #493d41, #493d41);
    }
  }
}

.event-vertical-line {
  position: relative;
  margin-bottom: 50px;

  &:before {
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 0;
    width: 10px;
    background: #bda27c;
    content: '';

    @include fluid-type(left, 384px, 1366px, -32px, -82px);
  }

  &:last-child {
    &:before {
      background: #bfcfd9;
    }
  }
}

.mb0 {
  margin-bottom: 0;
}

.px0 {
  padding-right: 0;
  padding-left: 0;
}
