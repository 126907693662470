// Fluid sizing
// Usage: @include fluid-type(font-size, 320px, 1366px, 14px, 20px);
// Usage: @include fluid-type(padding-top padding-bottom, 20rem, 70rem, 0rem, .5rem);
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

// Media Queries
// Usage: @include mq(xs, max || min) {}
@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type == min {
      $width: $width + 1;
    }

    @media (#{$type}-width: $width) {
      @content;
    }
  }
}
