.negative-margin-small {
  margin-top: -135px;
}

.negative-margin-big {
  margin-top: -300px;
}

.shadow {
  background: #fff;

  &.low {
    box-shadow: 2px 4px 12px 0 rgba(#000, 0.3);
  }

  &.med {
    box-shadow: 5px 7px 30px 0 rgba(#000, 0.25);
  }

  &.high {
    box-shadow: 10px 15px 60px 0 rgba(#000, 0.2);
  }
}

.title {
  font-weight: 300 !important; /* stylelint-disable-line declaration-no-important */
  letter-spacing: -1.5px;
  line-height: 1.3;
  color: map-get($colors, mainTextAlt);

  @include fluid-type(font-size, 384px, 1366px, 36px, 60px);
  @include fluid-type(margin-bottom, 384px, 1366px, 30px, 60px);

  @include mq(md, max) {
    letter-spacing: -1.2px;
  }

  &-small {
    font-weight: 400;
    margin-bottom: 15px;

    @include fluid-type(font-size, 384px, 1366px, 30px, 48px);
    @include fluid-type(margin-bottom, 384px, 1366px, 15px, 30px);
  }

  &.news-title {
    margin-bottom: 20px;
  }
}

.inline-title {
  margin: 30px 0;
  display: block;
}

.vert {
  &-center,
  &-bottom {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  &-center {
    justify-content: center;
  }

  &-bottom {
    justify-content: flex-end;
  }
}

.link {
  line-height: 1.3;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  min-height: 40px;

  @include clearfix;

  @include mq(lg, max) {
    font-size: 20px;
    min-height: 35px;
  }

  @include mq(md, max) {
    font-size: 16px;
    min-height: 30px;
  }

  svg {
    display: inline-block;
    float: left;
    color: #386c7d;
    transition: map-get($timing, duration);
    flex-grow: 0;
    flex-shrink: 0;

    @include fluid-type(flex-basis, 384px, 1366px, 26px, 40px);
    @include fluid-type(margin-right, 384px, 1366px, 14px, 20px);

    circle,
    path {
      transition: map-get($timing, duration);
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;

    path {
      transform: translateX(9px);
    }

    circle {
      opacity: 0;
    }
  }

  &.prev {
    &:hover {
      svg {
        path {
          transform: translateX(-9px);
        }
      }
    }
  }

  &.close {
    &:hover {
      svg {
        transform: translateX(0) rotate(90deg);

        path {
          transform: translateX(0);
        }

        circle {
          opacity: 0;
        }
      }
    }
  }

  &.center {
    margin: 0 auto;
  }

  &.right {
    margin-left: auto;
  }

  &-text {
    color: map-get($colors, mainTextAlt);
  }
}

.text-link {
  position: relative;
  line-height: 1.3;
  margin-bottom: 20px;
  color: map-get($colors, mainTextAlt);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 24px;
  padding-left: 60px;
  min-height: 40px;

  @include mq(lg, max) {
    font-size: 20px;
    padding-left: 50px;
    min-height: 35px;
  }

  @include mq(md, max) {
    font-size: 16px;
    padding-left: 40px;
    min-height: 30px;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    flex-grow: 0;
    flex-shrink: 0;
    color: map-get($colors, mainText);
    transform: translateY(-50%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: map-get($timing, duration);
    content: '';
    width: 40px;
    height: 40px;

    @include mq(lg, max) {
      width: 33px;
      height: 33px;
    }

    @include mq(md, max) {
      width: 26px;
      height: 26px;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &:before {
      opacity: 0;
    }

    &:after {
      transform: translate(9px, -20px);

      @include mq(lg, max) {
        transform: translate(9px, -16px);
      }

      @include mq(md, max) {
        transform: translate(9px, -13px);
      }
    }
  }

  &.prev {
    &:hover,
    &:focus {
      &:after {
        transform: translate(-9px, -20px);

        @include mq(lg, max) {
          transform: translate(-9px, -15px);
        }

        @include mq(md, max) {
          transform: translate(-9px, -10px);
        }
      }
    }
  }
}

.socials {
  @include clearfix;
}

.socials-link {
  display: inline-block;
  float: left;

  &:not(:last-child) {
    @include fluid-type(margin-right margin-bottom, 768px, 1366px, 20px, 40px);
  }
}

.socials-icon {
  display: block;

  @include fluid-type(width height, 768px, 1366px, 30px, 40px);
}

.separator {
  position: relative;
  width: 100%;

  @include fluid-type(height, 768px, 1366px, 30px, 50px);
}

.info-box {
  background: #d9e5ec;
  margin: 20px 0 35px;

  @include fluid-type(padding, 768px, 1366px, 20px, 40px);
}
