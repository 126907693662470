.thumbs {
  @include fluid-type(margin-bottom, 768px, 1366px, 60px, 240px);
}

.thumbs-inner {
  padding: 0 20px;

  @include wrapContent;
}

.thumbs-desc {
  columns: 300px 2;

  @include fluid-type(column-gap, 384px, 1366px, 20px, 40px);
  @include fluid-type(margin-bottom, 384px, 1366px, 30px, 50px);
}

.thumbs-list {
  margin: 0 -20px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;

  @include mq(lg, max) {
    margin: 0 -15px;
  }

  @include mq(md, max) {
    margin: 0 -10px;
  }
}

.thumbs-item {
  max-width: 33.33334%;
  flex: 0 0 33.33334%;
  padding: 0 20px;

  @include fluid-type(margin-bottom, 384px, 1366px, 40px, 80px);

  @include mq(lg, max) {
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 15px;
  }

  @include mq(md, max) {
    padding: 0 10px;
    flex-basis: 50%;
  }

  @include mq(sm, max) {
    max-width: 100%;
    flex-basis: 100%;
  }

  @include mq(xs, max) {
    flex-basis: 100%;
  }
}

.thumbs-link {
  width: 100%;
  color: map-get($colors, mainText);
  display: block;

  &:hover,
  &:focus {
    text-decoration: none;
    color: map-get($colors, mainTextAlt);
  }
}

.thumbs-image {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 65%;
  background-color: transparent;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @include fluid-type(margin-bottom, 384px, 1366px, 15px, 30px);

  .link {
    margin-bottom: 0;
  }

  &.as-holder {
    height: 62%;
    padding: 0;

    @include mq(md, max) {
      height: auto;
    }
  }

  &-overlay {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    background: rgba(#fff, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: map-get($timing, duration);

    @include mq(md, max) {
      font-size: 20px;
    }
  }

  &:hover {
    .thumbs-image-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

.thumbs-name {
  font-weight: 500;

  @include fluid-type(font-size, 384px, 1366px, 16px, 22px);

  span {
    font-weight: 400;
    display: block;
  }
}
