.partners {
  margin-top: 160px;

  @include wrapBody;
}

.partners-inner {
  padding: 0 20px;

  @include wrapContent;
}

.partners-item {
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    @include fluid-type(padding-bottom, 384px, 1366px, 80px, 160px);
  }

  @include mq(md, max) {
    flex-flow: column nowrap;
  }

  &.odd {
    .partners-image {
      @include fluid-type(padding-left, 768px, 1366px, 40px, 80px);
    }
  }

  &.even {
    .partners-content {
      order: 2;
    }

    .partners-image {
      @include fluid-type(padding-right, 768px, 1366px, 40px, 80px);
    }
  }
}

.partners-content {
  flex: 0 0 50%;
  max-width: 50%;

  @include mq(md, max) {
    flex-basis: 100%;
    max-width: 100%;
    order: 2;
  }
}

.partners-image {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  flex: 0 0 50%;

  @include mq(md, max) {
    padding: 0 20px !important; /* stylelint-disable-line declaration-no-important */
  }
}

.partners-title {
  font-weight: 300;
  display: block;
}

.partners-fulltext {
  display: none;
}

.partners-links-additional {
  @include mq(md, max) {
    margin-bottom: 50px;
  }
}
