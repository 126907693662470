@charset "utf-8";

// Vendors
@import '../../node_modules/flickity/dist/flickity.css';
@import '../../node_modules/magnific-popup/dist/magnific-popup.css';

// Config
@import 'config/variables';
@import 'config/tools';
@import 'config/mixins';

// Base
@import 'base/reset';
@import 'base/defaults';
@import 'base/helpers';

// Components
//@import 'components/cookienotice';
@import 'components/lightbox';
@import 'components/header';
@import 'components/intro';
@import 'components/searchform';
@import 'components/block';
@import 'components/meta';
@import 'components/filter';
@import 'components/thumbs';
@import 'components/slider';
@import 'components/contentblocks';
@import 'components/bullets';
@import 'components/tabs';
@import 'components/quote';
@import 'components/partners';
@import 'components/event-registration-form';
@import 'components/footer';
@import 'components/shared';
@import 'components/other';
@import 'components/variations';
@import 'components/vbcn';

.jobs {
  @include fluid-type(margin-bottom, 384px, 1366px, 80px, 160px);
}

.jobs-inner {
  padding: 0 20px;

  @include wrapContent;
}

.jobs-entry {
  &:not(:last-child) {
    @include fluid-type(margin-bottom, 384px, 1366px, 30px, 60px);
  }

  .block-column {
    @include fluid-type(padding-top, 384px, 1366px, 30px, 68px);
    @include fluid-type(padding-bottom, 384px, 1366px, 20px, 40px);
    @include fluid-type(padding-right padding-left, 384px, 1366px, 20px, 60px);
  }

  .block-text {
    width: 100%;
    max-width: 650px;
  }

  .column {
    &:nth-child(2) {
      @include mq(lg, max) {
        margin-top: 30px;
      }
    }
  }

  .title {
    margin-bottom: 20px;
  }

  .link {
    margin-bottom: 0;

    @include mq(lg, max) {
      margin-right: auto;
    }
  }
}

.parallax {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: transparent;
}
